@use "sass:math";
@use '@angular/material' as mat;

@include mat.core();

/*
==========================================================================
Body - main responsive (Aurora overwrite)
==========================================================================
*/

body {
    --font-size-h1: 40px;
    --font-size-h2: 24px;
    --font-size-h3: 16px;
    --font-size-h4: 14px;
    --font-size-h5: 14px;
    --font-size-h6: 14px;

    --seatmap-available: #70AD47;
    --seatmap-issued: #FF4A00;
    --seatmap-reserved: #44546A;
    --seatmap-unavailable: #9CA3AB;
    --seatmap-current-order: #FFBF00;
    --seatmap-subscription: #BF0000;

    --default-notification-offset-top: 80px;

    --mdc-outlined-text-field-outline-width: 0;
}

body, h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-base), sans-serif;
}

:has(.order-footer.expanded-full) .notify-container {
    top: calc(var(--default-notification-offset-top) + 64px);
}

cm-tab {
    padding: 0 !important;
    overflow: hidden;
    height: calc(100% - 20px);
}

.form-group .form-input {
    padding-right: 8px;
}

.search-barcode .form-group .form-row {
    margin-bottom: 0;
}

.form-input [data-component="cm-select"] + span {
    display: none;
}

.btn-icon-inline {
    position: relative;
    top: 5px;
}

.form-check label {
    white-space: unset;
}

.header-link {
    display: block;
    color: var(--selected-color);
    cursor: pointer;
}

.summary-title {
    font-weight: 100;
    font-size: 12px;
}

.summary-value {
    font-size: 16px;
    margin-bottom: 16px;
}

cm-indicator {
    margin-left: auto;
    margin-right: auto;
}

.datatable .datatable-row .datatable-row-item,
.datatable .datatable-header, .datatable .datatable-row {
    height: auto;
}

.h-100 {
    height: 100%;
}

select.form-control {
    border: var(--input-border) !important;
}

.conflict-banner cm-tooltip {
    height: 24px;
}

/*
==========================================================================
Align
==========================================================================
*/

.center {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

/*
==========================================================================
Font-size
==========================================================================
*/
@for $i from 0 through 36 {
    //Font Size
    .f-s-#{$i} {
        font-size: 1px * ($i) !important;
    }
}

@for $i from 0 through 9 {
    //Font Size
    .f-w-#{100 * $i} {
        font-weight: 100 * ($i) !important;
    }
}

@for $i from 0 through 36 {
    //Font Size
    .l-h-#{$i} {
        line-height: 1px * ($i) !important;
    }
}

.full-width {
    width: 100%;
}

.w-100 {
    width: 100% !important;
}

.w-75 {
    width: 75% !important;
}

.w-50 {
    width: 50% !important;
}

.w-25 {
    width: 25% !important;
}

/*
==========================================================================
Table
==========================================================================
*/
.loading td, td.loading {
    opacity: 0.25;
}

/*
==========================================================================
Buttons
==========================================================================
*/

.btn.btn-primary.btn-success,
.btn.btn-primary-icon.btn-success,
.btn.btn-primary-icon-text.btn-success {
    background: var(--bg-success-color) !important;
    color: var(--cl-success-color) !important;

    &:hover {
        background: var(--bg-success-color) !important;
    }
}

.btn.btn-primary.btn-warning,
.btn.btn-primary-icon.btn-warning,
.btn.btn-primary-icon-text.btn-warning {
    background: var(--bg-warning-color) !important;
    color: var(--cl-warning-color) !important;

    &:hover {
        background: var(--bg-warning-color) !important;
    }
}

.btn.btn-primary.btn-error,
.btn.btn-primary-icon.btn-error,
.btn.btn-primary-icon-text.btn-error {
    background: var(--bg-error-color) !important;
    color: var(--cl-error-color) !important;

    &:hover {
        background: var(--bg-error-color) !important;
    }
}

/* --- */

.btn.btn-cta.btn-success,
.btn.btn-cta-icon.btn-success,
.btn.btn-cta-icon-text.btn-success {
    background: var(--cl-success-color) !important;
    color: var(--default-color-inverse) !important;

    &:hover {
        background: var(--cl-success-color) !important;
    }
}

.btn.btn-cta.btn-warning,
.btn.btn-cta-icon.btn-warning,
.btn.btn-cta-icon-text.btn-warning {
    background: var(--cl-warning-color) !important;
    color: var(--default-color-inverse) !important;

    &:hover {
        background: var(--cl-warning-color) !important;
    }
}

.btn.btn-cta.btn-error,
.btn.btn-cta-icon.btn-error,
.btn.btn-cta-icon-text.btn-error {
    background: var(--cl-error-color) !important;
    color: var(--default-color-inverse) !important;

    &:hover {
        background: var(--cl-error-color) !important;
    }
}

/* --- */

.btn.btn-secondary.btn-success,
.btn.btn-secondary-icon.btn-success,
.btn.btn-secondary-icon-text.btn-success {
    background: var(--background) !important;
    color: var(--cl-success-color) !important;

    &:hover {
        background: var(--background) !important;
    }
}

.btn.btn-secondary.btn-warning,
.btn.btn-secondary-icon.btn-warning,
.btn.btn-secondary-icon-text.btn-warning {
    background: var(--background) !important;
    color: var(--cl-warning-color) !important;

    &:hover {
        background: var(--background) !important;
    }
}

.btn.btn-secondary.btn-error,
.btn.btn-secondary-icon.btn-error,
.btn.btn-secondary-icon-text.btn-error {
    background: var(--background) !important;
    color: var(--cl-error-color) !important;

    &:hover {
        background: var(--background) !important;
    }
}

/*
==========================================================================
Table
==========================================================================
*/
table {
    border-collapse: collapse;
    font-size: 12px;
    line-height: 28px;
}

tbody, td, tfoot, th, thead, tr {
    margin: 0;
    padding: 0;
    vertical-align: middle;
}

table thead th {
    text-align: left;
    margin: 8px 0;
    color: var(--text-color);
}

body:not(.dark) {
    .filter.white {
        background: var(--black-5);
    }

    thead {
        tr.white {
            background: white;
        }
    }

    tbody td.is-total {
        background: var(--hint-text-color);
    }
}

table thead tr th,
table tbody tr td {
    padding: 12px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

table tr:not(:last-of-type) {
    border-bottom: var(--default-small-border);
}

.table.table-hover tbody tr:hover {
    cursor: pointer;
    background: var(--default-background);
}

.table.table-hover tbody tr:hover .fixed-column {
    cursor: pointer;
    background-color: transparent;
}

.table .fade-out td, .fade-out {
    opacity: 0.5;
}

/*
==========================================================================
Aurora, angular material fix
==========================================================================
*/
cm-time-zone-popup {
    z-index: 99999999;
}

cm-datetime-picker .form-input-validation {
    display: none;
}

.cm-tab-content {
    margin-top: 32px;
}

footer.cm-action-footer .container .content {
    font-weight: 700;
    line-height: 1.5;
}

.list-group .list-group-content.disabled {
    background-color: #EBECEE;
}

.list-view:not(.card-wrapper) .card-container .card:hover, .list-view:not(.card-wrapper) .card.card-active {
    border: var(--selected-border);
    background-color: var(--selected-background);
    color: var(--selected-color) !important;
}

.list-view .title {
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.list-view .list-item.disabled {
    background-color: var(--default-background);
}

.card {
    min-height: 80px;
    padding: 40px;
    border-radius: 16px;
    border: var(--default-border);
}

.card.card-action:hover {
    border-color: var(--selected-color);
}

.card.card-action.card-add:hover {
    cursor: pointer;
}

.card.card-action .delete {
    position: absolute;
    right: 110px;
}

.card.card-add {
    border-style: dashed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    color: var(--label-sub-color);
    outline: 0;
    padding: 0;
    min-height: 40px;
}

.card.card-add.card-action:hover {
    color: var(--selected-color);
    background-color: var(--bg-selected-light-color);
}

.change-event-hover:hover {
    cursor: pointer;
    color: var(--selected-color);
}

.hoverable:hover {
    cursor: pointer;
    color: var(--selected-color);
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto
}

@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive > .table {
        margin-bottom: 0
    }

    .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > td, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > thead > tr > th {
        white-space: nowrap
    }

    .table-responsive > .table-bordered {
        border: 0
    }

    .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > thead > tr > th:first-child {
        border-left: 0
    }

    .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > thead > tr > th:last-child {
        border-right: 0
    }

    .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > th {
        border-bottom: 0
    }
}

cm-indicator.small .loader {
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
}

.card {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 32px;
    border-radius: 16px;
    border: var(--default-border);
    color: var(--label-color);
    background: var(--background);
}

.card .card-header {
    padding: 8px 8px 16px 8px;
}

.card .card-header .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    padding: 8px 8px 16px 8px;
}

.card .card-header .subtitle {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
    padding: 8px 8px 16px 8px;
    margin-top: -24px;
}

.card .card-content {
    padding: 16px;
}

.card .card-footer {
    text-align: right;
    padding: 16px;
    border-radius: 0;
    border: 0;

    &.border-top {
        border-top: var(--default-border);
    }

    button {
        margin-left: 16px;
    }
}

.card .card-footer-action {
    padding: 16px;
    border-top: var(--default-border);
}

.card-footer .text-left button {
    margin-left: 0;
    margin-right: 16px;
}

.list-view {
    position: relative;
}

.list-item {
    min-height: 86px !important;
    position: relative;
    border: var(--default-border);
    border-radius: 16px;
    margin-bottom: 16px;
    overflow: hidden;
    padding: 16px;
    cursor: pointer;
    transition: all .15s;
    outline: none;

    .list-item-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 50px;
        align-self: center;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            overflow: visible;
            white-space: normal;
        }
    }
}

.list-item:hover, .list-item.selected, .list-item.active, .list-item:focus {
    &:not(.disabled) {
        border: var(--selected-border);
        background: var(--default-active-background);
        color: var(--label-focus-color);
        outline: none;
    }
}

.list-view .list-item .list-item-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 50px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.page-header {
    position: relative;
}

.page-actions {
    margin-bottom: 16px;

    .btn {
        margin-left: 8px;
    }
}

.page-content {
    .page-section {
        margin-bottom: 64px;
    }
}

.overflow-y {
    overflow-y: auto;
}

.divider-right {
    border-right: var(--default-border);
}

.card-divider {
    border-bottom: var(--default-small-border);
    width: calc(100% + 32px);
    left: -16px;
    position: relative;
    margin: 32px 0;
}


.loader .circular-spinner .circular-spinner-image {
    transform: scale(0.6);
    position: relative;
    left: -1px;
    top: 2px;
}

.loader.image .circular-spinner {
    top: -5px;
}

.card-fill {
    width: calc(100% + 32px);
    margin-left: -16px;
    display: block;
}

.fixed-column {
    position: sticky;
    max-width: 200px;
    width: 200px;
    min-width: 200px;
    left: 0;
    z-index: 5;
    background: var(--background);
}

@media (max-width: 576px) {
    .fixed-column {
        max-width: 100px;
        width: 100px;
        min-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .fixed-column:active {
        overflow: visible;
    }
    .fixed-column:active * {
        background: var(--background);
    }
}

.no-results-title {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 24px 0;
}

td.no-results-title {
    text-align: center;
}

td.no-results-title:hover {
    background: var(--background);
    cursor: default;
}

.cm-option-empty {
    display: inline-block;
    padding: 4px 8px;
    height: 48px;
    line-height: 24px;
    vertical-align: middle;
    color: var(--input-color) !important;
    font-size: 14px;
    font-weight: 700;
}

.breadcrumb {
    margin: 8px 0 0;
    padding: 0;
}

.notify-item .meta-info-title::first-letter {
    text-transform: capitalize;
}

.vertical-middle {
    align-self: center;
    vertical-align: top;
}

/*
==========================================================================
Card
==========================================================================
*/
.card-action .list-row {
    border-bottom: 1px solid var(--default-color-light);
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.card-action .list-row:last-child {
    border-bottom: 0;
}

.card-action .list-row-item- {
    padding-left: 16px;
    padding-top: 8px;
}

@media (max-width: 576px) {
    .card-action .list-row-item {
        padding-left: 0;
        padding-top: 8px;
    }
}

/*
==========================================================================
Drag and drop
==========================================================================
*/
.drag-drop-container {
    position: relative;
}

.drag-drop-handle {
    position: absolute;
    top: 20px;
    right: 16px;
    z-index: 10;
    cursor: ns-resize;
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
}

.cdk-drag-placeholder {
    opacity: 0;
    background: transparent;
    border-radius: 16px;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-drop-container.cdk-drop-list-dragging .drag-drop-container:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-drop-container .cm-icon-reorder {
    color: var(--black-25);
}

.drag-drop-container:hover .cm-icon-reorder {
    color: var(--default-inverse-color);
}

/*
==========================================================================
Dropdown
==========================================================================
 */
.dropdown-button {
    color: var(--selected-color);
    background-color: var(--selected-background);
    line-height: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    transition: background-color .24s, color .24s;
    font-weight: 700;

    &:hover {
        cursor: pointer;
        background: var(--blue-25);
    }
}

/*
==========================================================================
Card with left menu
==========================================================================
*/
.card-menu-container {
    transition: all .15s;
    margin-bottom: 0;
    z-index: 15;
    overflow: hidden;
    padding: 0;
}

.card-menu-container.closed {
    max-height: 80px;
    overflow: hidden;
    z-index: 5;
}

.card-menu-container.closed .card-menu-content,
.card-menu-container.closed .card-menu,
.card-menu-container.closed .card-menu-content-disabled {
    height: 100%;
    opacity: 0;
    transition: all .15s;
}

.card-menu-container.closed .card-menu-header {
    border-bottom: 0;
}

.card-menu-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    padding: 16px;
    border-bottom: var(--default-border);
    background: var(--background);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    max-height: 74px;
    cursor: pointer;
}

.card-menu {
    border-right: var(--default-border);
    width: 28%;
    min-height: 400px;
    max-height: 700px;
    overflow-y: auto;
}

.card-menu-item {
    position: relative;
    min-height: 58px;
    border-bottom: var(--default-border);
    padding: 16px;
    cursor: pointer;

    .form-switch .switch {
        width: 40px;
        display: flex;
    }

    .form-switch-label {
        position: absolute;
        top: 18px;
        left: 72px;
        font-weight: 700;
        color: var(--label-color);
    }
}

.card-menu-item:last-child {
    border: none;
}

.card-menu-item.active {
    background: var(--selected-background);
}

.card-menu-item.inactive {
    background: var(--input-disabled-bg);
    cursor: default;
}

.card-menu-item .form-row {
    margin: 0;
}

.card-menu-content {
    width: 72%;
    position: absolute;
    top: 0;
    left: 28%;
    padding: 16px;
    height: calc(100% - 74px);
    margin-top: 74px;
    overflow-x: hidden;
    overflow-y: auto;

    .full-width {
        position: relative;
        top: -16px;
        left: -16px;
        width: calc(100% + 32px);
    }
}

.card-menu-content-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: var(--background);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

/*
==========================================================================
Pointer
==========================================================================
*/
.cursor-pointer {
    cursor: pointer !important;
}

.select-option-multiline {
    display: inline-block;
    padding: 12px 8px;
    height: auto;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 700;
    width: 97%;
}

.select-option-multiline .title {
    color: var(--input-color) !important;
}

.select-option-multiline .subtitle {
    font-size: 12px;
    color: var(--hint-text-color);
}


.select-option-multiline:hover .title {
    font-weight: bold;
}

.overflow-hidden {
    overflow: hidden;
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-45 {
    transform: rotate(45deg);
}

.vertical-align-top {
    vertical-align: top;
}

.icon-hover {
    color: var(--hint-text-color);
    cursor: pointer;
}

.icon-hover:hover {
    color: var(--text-color);
}

app-analytics-dashboard gridster {
    background-color: unset;

    gridster-item {
        background-color: unset;
    }
}

gridster-preview.gridster-preview {
    background: var(--spinner-color-bg);
}

//@import 'https://code.highcharts.com/css/highcharts.css';

.highcharts-tooltip > span {
    background-color: var(--black-80);
    border-radius: 8px;
    color: var(--white) !important;
    box-shadow: 0 10px 30px rgba(16, 30, 30, 0.25);
    padding: 16px;
}

.highcharts-label.highcharts-tooltip b {
    font-weight: normal;
}

.highcharts-background {
    fill: var(--background);
}

.highcharts-grid-line {
    opacity: 0;
    stroke: var(--chart-grid-line);
}

.horizontal-grid .highcharts-yaxis-grid .highcharts-grid-line {
    opacity: 1;
}

.vertical-grid .highcharts-xaxis-grid .highcharts-grid-line {
    opacity: 1;
}

.highcharts-axis-line {
    stroke: none;
}

.horizontal-grid .highcharts-xaxis .highcharts-axis-line {
    stroke: var(--chart-grid-line);
}

.highcharts-legend-item text {
    fill: var(--label-color) !important;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info-banner {
    border-radius: 16px;
    padding: 8px;
    background: var(--bg-selected-light-color);
    margin-bottom: 32px;
    color: var(--selected-color-highcontrast);
}

.info-page-banner {
    position: absolute;
    top: 75px;
    width: 100%;
    left: 0;
    padding: 8px;
    z-index: 9999;
}

h1, h2, h3, h4 {
    &.has-subtitle {
        margin-bottom: 0;
    }
}

.page-header-subtitle {
    margin-bottom: 32px;
}

.row.gutter-16 > div {
    padding: 0 16px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
}

.datatable-hover .datatable-row:hover {
    background: var(--bg-selected-light-color);
    cursor: pointer;
}

.page-section:last-child {
    margin-bottom: 0;
}

.reset-line-height {
    line-height: normal !important;
}

.email-template-editor-container {
    #odb-message-text:hover {
        margin-left: 0;
    }

    table {
        line-height: unset !important;
    }

    table thead tr th, table tbody tr td {
        white-space: unset !important;
        overflow: unset !important;
        text-overflow: unset !important;

        & #footer-banner-cell {
            padding: unset;
        }
    }

    table tr {
        border-bottom: unset !important;

        &:not([class*=bg-]):nth-child(2n) {
            background: unset;
        }
    }
}

@media print {
    body platform-header,
    cm-sidenav,
    .chatbtn,
    app-statistics,
    .no-print {
        display: none !important;
    }

    .container {
        width: 100vw;
        margin: 0;
        padding: 0 !important;
        filter: none !important;
    }

    * {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }
}

.inline-notification {
    display: inline-flex;
    width: fit-content;
    margin-right: 8px;
    cursor: pointer;

    .title {
        min-width: unset;
        padding-right: 8px;
    }
}

.page-intro {
    max-width: 60ch;
    margin-bottom: 24px;
}

.datatable .datatable-header .datatable-header-item,
.datatable .datatable-row .datatable-row-item {
    font-size: 12px;
}

cm-tab-group {
    display: block;
}

.mat-mdc-paginator .mat-mdc-select {
    background: var(--background);
    border: var(--default-border);
    border-radius: 16px;
    padding: 4px 8px;
}

.cdk-overlay-pane {
    background: var(--background);
}

.mat-mdc-select-panel {
    background: var(--background);
    border: var(--default-border);
    border-radius: 16px;
}

.breadcrumb-item {
    color: var(--text-color);
}

.cm-icon.cl-error-color {
    color: var(--red-100);
}

.cm-icon.cl-success-color {
    color: var(--green-100);
}

.cm-icon.cl-selected-color {
    color: var(--blue-100);
}

.cm-icon.cl-warning-color {
    color: var(--orange-100);
}

.has-description {
    margin-bottom: 0;
}

body:not(:has(.order-footer-title)) cm-sidenav-option:last-of-type {
    display: none;
}

cm-pagination {
    display: block;
    margin-top: 16px;
    position: relative;
}

body:not(.dark).focus {
    background: #fbfbfc !important;
}

.cl-selected-color-high-contrast {
    color: var(--selected-color-highcontrast);
}

tbody {
    tr:not([class*=bg-]):nth-child(2n) {
        background: var(--table-background-color);
    }
}

.empty-state {
    text-align: center;
    margin-top: 40px;

    .empty-state-header {
        font-size: 18px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 4px;
    }

    .empty-state-description {
        margin-bottom: 32px;
    }

    cm-image {
        display: block;
        margin-top: 64px;
    }
}

@media (min-width: 576px) {
    body {
        margin-bottom: 160px;
    }

    .empty-state {
        margin-top: 80px;

        .empty-state-header {
            margin-top: 48px;
            margin-bottom: 16px;
        }

        .empty-state-description {
            margin-bottom: 16px;
        }

        cm-image {
            margin-top: 0;
        }
    }

    cm-ticketing-data-table {
        width: unset;
        margin-left: 0;
    }
}

.skeleton-loading {
    border-radius: 4px;
    height: 12px;
    width: calc(100% - 50px);
    min-width: 32px;
    margin: 6px 0;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes moving-gradient {
    0% {
        background-position: -250px 0;
    }
    100% {
        background-position: 250px 0;
    }
}

.skeleton-loading {
    border-radius: 4px;
    height: 12px;
    width: calc(100% - 50px);
    min-width: 32px;
    margin: 6px 0;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes moving-gradient {
    0% {
        background-position: -250px 0;
    }
    100% {
        background-position: 250px 0;
    }
}

.lowercase {
    text-transform: lowercase;
}

// Aurora fix
.status-badge {
    margin: 0;
}

app-input-translation {
    p {
        color: var(--input-color) !important;
    }
}


cm-date-time-range-picker cm-date-time-input:nth-child(2) {
    margin-right: 0 !important;
}

@media (min-width: 576px) {
    cm-date-time-range-picker cm-date-time-input:nth-child(2) {
        margin-right: 0 !important;
    }
}

.inline-notification {
    cursor: default;
}

.capitalize {
    text-transform: capitalize;
}

body.action-footer {
    app-blockade-action-footer .action-footer {
        bottom: 96px;
    }

    app-venue-section-master-detail.fullscreen {
        height: calc(98vh - 80px);
    }
}

.select-rect {
    stroke: var(--selected-color);
    stroke-width: 2px;
    fill: var(--selected-color);
    fill-opacity: .4;
}

cm-image h4 {
    font-size: 20px;
}

.list-group-content > .table-column small + div {
    font-weight: bold;
}

.list-group:empty {
    display: none;
}

.list-group > :first-child {
    border-top: 0 !important;
}

.list-group-content {
    cursor: pointer;
}

.list-group-content.no-border {
    border: none !important;
}

.list-group-content.no-border-top {
    border-top: none !important;
}

cm-table-toolbar span.m-l-16 {
    display: none;
}

.form-check-label:hover {
    overflow: visible;
    white-space: normal;
}

#aurora-notification {
    top: 0;
}

.btn.btn-secondary-icon-text.text-decoration-none:hover.btn-secondary-icon-text:not(:disabled) > span {
    text-decoration: none !important;
    opacity: .64;
}

.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline__trailing {
    border: none !important;
    display: none;
}

.mat-mdc-icon-button:not(:disabled) svg, .mat-mdc-icon-button:not(:disabled) img {
    width: 28px !important;
}

mat-tooltip-component {
    display: none !important;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}

.top-0 {
    top: 0;
}

.right-0 {
    right: 0;
}

.z-index-999 {
    z-index: 999;
}

.cta-fix {
    top: -6px;
}
